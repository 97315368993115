import create from 'zustand';

type UseIsLoadingType = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const useIsLoading = create<UseIsLoadingType>(
  set => ({
    isLoading: false,
    setIsLoading: newIsLoading => set(() => ({ isLoading: newIsLoading }))
  })
);

export default useIsLoading;