import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { NextPage } from 'next';
import Aside from 'components/Aside/Aside';
import { DescriptionParagraph } from 'common/styles/DescriptionParagraph.styled';
import { FeatureFlags } from 'model/enums';
import useGroup from 'hooks/useGroup';
import { getApolloError } from 'graphQL/client';
import { IOffer, IProduct } from 'model/interfaces';
import { commonGetServerSideProps } from 'common/pages/CommonPage';
import MainAsideLayout from 'components/Layout/MainAsideLayout';
import dynamic from 'next/dynamic';
import { H1 } from 'common/styles/Headings.styled';
import useProductEvents from 'hooks/useProductEvents';
import useCountryCode from 'hooks/useCountryCode';
import useCurrency from 'hooks/useCurrency';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import useOnlyConfiguration from 'hooks/useOnlyConfiguration';
import useBusinessUnit from 'hooks/useBusinessUnit';
import useIsLoading from 'hooks/useIsLoading';
import useCluster from 'hooks/useCluster';
import useIsInstantPayment from 'hooks/useIsInstantPayment';
import { paths } from 'model/constants';
import useQueryParmasStore from 'hooks/useQueryParamsStore';

const ApplicantForm = dynamic(
  () => import('components/Forms/ApplicantForm/ApplicantForm')
);

const ProductGroupList = dynamic(
  () => import('components/ProductGroup/ProductGroupList')
);

interface Props {
  product: IProduct;
  offer: IOffer;
  isInstantPayment: boolean;
  featureFlags?: FeatureFlags[];
}

type Page = NextPage<Props> & { Layout?: React.FC };

const SelectGroupPage: Page = ({ product, offer, isInstantPayment }) => {
  const { params } = useQueryParmasStore();
  const [country] = useCountryCode();
  const isLoading = useIsLoading(state => state.isLoading);
  const { error, groups } = useProductEvents(product.id, country as string);
  const { t } = useTranslation('main');
  const setOnlyConfiguration = useOnlyConfiguration(
    state => state.setOnlyConfiguration
  );
  const setBusinessUnit = useBusinessUnit(state => state.setBusinessUnit);
  const { name: productName } = useCluster(product);
  const { setIsInstantPayment } = useIsInstantPayment();

  if (error) {
    const e = getApolloError(error);
    router.replace(
      {
        pathname: e.code === 404 ? paths.error : paths.serverError
      },
      router.asPath,
      { shallow: true }
    );
  }
  const { group, fixed: fixedGroup } = useGroup(product);
  const currency = useCurrency(country);
  const [applicantFormSubmit, setApplicantFormSubmit] = useState<() => void>(
    () => { }
  );

  const [noOffer, setNoOffer] = useState<boolean>(false);

  useEffect(() => {
    if (!offer.plans.length || (!!groups && groups.length === 0)) {
      setNoOffer(true);
    }
  }, [groups]);

  const [formVisible, setFormVisible] = useState<boolean>(false);

  useEffect(() => {
    setFormVisible(!!group);
  }, [group]);

  useEffect(() => {
    setOnlyConfiguration(!!product?.onlyConfiguration);
  }, [product?.onlyConfiguration]);

  useEffect(() => {
    setBusinessUnit(product.businessUnit);
  }, [product.businessUnit]);

  useEffect(() => {
    setIsInstantPayment(isInstantPayment);
  }, [isInstantPayment]);

  const isFreemium = product.freemiumSkipDays !== 0;
  const title = isFreemium ? t('group.titleFree') : t('group.title');

  return (
    <>
      <Head>
        <title>{t('group.metaTitle', { productName })}</title>
        <meta
          property="og:title"
          content={t('group.metaTitle', { productName })}
          key="title"
        />
      </Head>

      {noOffer && (
        <>
          <p>{t('group.unavailableProducts', { productName })}</p>
        </>
      )}

      {!noOffer && (
        <>
          <H1>{title}</H1>
          <DescriptionParagraph>{t('group.description')}</DescriptionParagraph>
          <ProductGroupList product={product} groups={groups} />

          {group && (
            <ApplicantForm
              product={product}
              group={group}
              setFormSubmit={setApplicantFormSubmit}
              visible={formVisible}
              country={country}
            />
          )}
        </>
      )}

      <Aside
        product={product}
        group={group}
        offer={!product.onlyConfiguration ? offer : null}
        disableButton={!formVisible || isLoading}
        isLoading={isLoading}
        onContinue={applicantFormSubmit}
        currency={currency}
        country={country}
        editableGroup={!fixedGroup}
        showInfo={true}
      />
    </>
  );
};

export const getServerSideProps = commonGetServerSideProps;

export default SelectGroupPage;

SelectGroupPage.Layout = MainAsideLayout;
