import { ApolloError, useQuery } from '@apollo/client';
import { FetchPolicy } from 'graphQL/client';
import {
  GetProductEventsData,
  GetProductVars,
  GET_PRODUCT_EVENTS
} from 'graphQL/queries';
import { IProductGroup } from 'model/interfaces';

type ProductQuery = {
  error?: ApolloError;
  groups?: IProductGroup[];
};

export const useProductEvents = (id: string, country: string): ProductQuery => {
  const {
    error,
    data: { getAllEvents: groups } = {
      getAllEvents: undefined
    }
  } = useQuery<GetProductEventsData, GetProductVars>(GET_PRODUCT_EVENTS, {
    variables: { id, country: country },
    fetchPolicy: FetchPolicy['cache-first']
  });
  return { error, groups };
};

export default useProductEvents;
